<template>
    <div class="pb-10">
        <div class="bg-custom-100 pt-10 pb-24">
            <div class="max-w-7xl mx-auto px-6 lg:px-8">
                <Header name="Privacy Policy" subtitle="Last updated: November 27, 2020" />
            </div>
        </div>

        <div class="py-10 -mt-24">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div class="grid grid-cols-3 gap-4">
                    <div class="col-span-3">
                        <Card :hide-shadow-mobile="true">
                            <div class="px-4 py-5 sm:p-6">
                                <h3 class="font-medium text-gray-900 text-lg leading-6">Introduction</h3>
                                <div class="mt-4 text-sm leading-5 text-gray-500">
                                    Privacy Policy BrandAmbassadorLast Updated: November 27, 2020IntroductionYour privacy and the safety of your personal data is important to us. Therefore, we created this privacy policy to let you know how we take care your data and what our legal obligations are regarding the safety of your personal information.
                                </div>
                                <div class="mt-4 text-sm leading-5 text-gray-500">
                                    BrandAmbassador ("us", "we", or "our") operates the website www.brand-ambassador.com (the "Service").
                                </div>
                                <div class="mt-4 text-sm leading-5 text-gray-500">
                                    The reason why we use your data is to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. If you have anyquestions regarding this Privacy Policy you can contact us by sending an email to info@brand-ambassador.com.
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        name: "PrivacyPolicy",
        setup() {
        }
    })
</script>